define("discourse/plugins/discourse-epic-games/discourse/components/unreal-sidebar", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/utils", "discourse/lib/avatar-utils", "discourse/lib/cookie", "discourse/lib/get-url", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _utils, _avatarUtils, _cookie, _getUrl, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MOBILE_SHOW = _exports.MOBILE_HIDE = void 0;
  const MOBILE_SHOW = _exports.MOBILE_SHOW = "epic-edc-sidebar-on-mobile-show";
  const MOBILE_HIDE = _exports.MOBILE_HIDE = "epic-edc-sidebar-on-mobile-hide";
  class UnrealSidebar extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "epicFilteredCategories", [_service.service]))();
    #epicFilteredCategories = (() => (dt7948.i(this, "epicFilteredCategories"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "discussions_posts_count", [_tracking.tracked], function () {
      return 0;
    }))();
    #discussions_posts_count = (() => (dt7948.i(this, "discussions_posts_count"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "discussions_answers_count", [_tracking.tracked], function () {
      return 0;
    }))();
    #discussions_answers_count = (() => (dt7948.i(this, "discussions_answers_count"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "readyToRenderSidebar", [_tracking.tracked], function () {
      return false;
    }))();
    #readyToRenderSidebar = (() => (dt7948.i(this, "readyToRenderSidebar"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "mobileShown", [_tracking.tracked], function () {
      return false;
    }))();
    #mobileShown = (() => (dt7948.i(this, "mobileShown"), void 0))();
    constructor() {
      super(...arguments);
      // listen for tap that brings the sidebar out of its minimal, low-height state
      // and back into it.
      window.document.addEventListener(MOBILE_SHOW, () => {
        this.mobileShown = true;
      });
      window.document.addEventListener(MOBILE_HIDE, () => {
        this.mobileShown = false;
      });
      // don't show sidebar until we've called currentUser.findDetails
      // this loads backgroundUrl as well as user stats.
      if (!this.currentUser) {
        this.readyToRenderSidebar = true;
      } else {
        this.currentUser.findDetails().then(() => {
          this.readyToRenderSidebar = true;
          this.discussions_posts_count = this.currentUser.get("post_count") || 0;
          this.discussions_answers_count = this.currentUser.get("answer_count") || 0;
        });
      }
    }
    get locale() {
      // we are expecting that SiteSetting.set_locale_from_cookie is set to true
      // so that we fetch the locale from the cookie for anon users.
      // Additionally, Epic Sidebar has a mapping for locales that are not
      // supported by the sidebar. We need to map these to a supported locale.
      return _constants.EPIC_SIDEBAR_LOCALE_MAP[(0, _cookie.default)("locale")] || (0, _cookie.default)("locale") || "en-US";
    }
    get categories() {
      return this.epicFilteredCategories.categories;
    }
    get applicationFamily() {
      return this.epicFilteredCategories.globalFilter;
    }
    get categoryLinks() {
      const capturedThis = this;
      function transformCategory(category) {
        const transformedCategory = {};
        transformedCategory.title = category.name;
        transformedCategory.pathname = `/tags/c/${category.slug}/${category.id}/${capturedThis.applicationFamily}`;
        transformedCategory.count = category.topic_count;
        if (category.subcategories) {
          transformedCategory.subItems = transformCategories(category.subcategories); //recurse
        }
        return transformedCategory;
      }
      function transformCategories(categories) {
        return categories.map(category => transformCategory(category));
      }
      const parentCategories = this.categories?.filter(cat => !cat.parent_category_id);
      const categories = transformCategories(parentCategories);
      return JSON.stringify(categories);
    }
    get userBackgroundImageUrl() {
      if (!this.currentUser) {
        return null;
      }
      const url = this.currentUser.card_background_upload_url;
      const bg = (0, _utils.isEmpty)(url) ? "" : (0, _getUrl.getURLWithCDN)(url);
      return bg;
    }
    get userBadges() {
      if (!this.currentUser) {
        return "";
      }
      const badges = (this.currentUser.badges || []).map(_ => _.name.toLowerCase());
      return JSON.stringify(badges);
    }
    get userAvatarUrl() {
      if (this.currentUser) {
        const avatarTemplate = this.currentUser.get("avatar_template");
        return (0, _avatarUtils.avatarUrl)(avatarTemplate, "huge", {});
      }
    }
    get totalUnreadNotifications() {
      if (!this.currentUser?.get("grouped_unread_notifications")?.length) {
        return;
      }
      let total = 0;
      Object.values(this.currentUser.get("grouped_unread_notifications")).forEach(value => {
        const parsed = parseInt(value, 10);
        if (isNaN(parsed)) {
          return;
        }
        total += value;
      });
      return total > 50 ? 50 : total;
    }
    static #_10 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="above-site-header-outlet sidebar">
          <div class="unreal-sidebar">
            {{#if this.readyToRenderSidebar}}
    
              <edc-sidebar
                app-context="discourse"
                user-hash-id={{this.currentUser.id}}
                notifications-count={{this.totalUnreadNotifications}}
                user-avatar-url={{this.userAvatarUrl}}
                user-username={{this.currentUser.username}}
                user-name={{this.currentUser.name}}
                locale={{this.locale}}
                application-family={{this.applicationFamily}}
                user-background-image-url={{this.userBackgroundImageUrl}}
                can-access-manage="true"
                category-links={{this.categoryLinks}}
              />
            {{/if}}
          </div>
        </div>
      
    */
    {
      "id": "xwQV2xhJ",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"above-site-header-outlet sidebar\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"unreal-sidebar\"],[12],[1,\"\\n\"],[41,[30,0,[\"readyToRenderSidebar\"]],[[[1,\"\\n          \"],[10,\"edc-sidebar\"],[14,\"app-context\",\"discourse\"],[15,\"user-hash-id\",[30,0,[\"currentUser\",\"id\"]]],[15,\"notifications-count\",[30,0,[\"totalUnreadNotifications\"]]],[15,\"user-avatar-url\",[30,0,[\"userAvatarUrl\"]]],[15,\"user-username\",[30,0,[\"currentUser\",\"username\"]]],[15,\"user-name\",[30,0,[\"currentUser\",\"name\"]]],[15,\"locale\",[30,0,[\"locale\"]]],[15,\"application-family\",[30,0,[\"applicationFamily\"]]],[15,\"user-background-image-url\",[30,0,[\"userBackgroundImageUrl\"]]],[14,\"can-access-manage\",\"true\"],[15,\"category-links\",[30,0,[\"categoryLinks\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/unreal-sidebar.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UnrealSidebar;
});