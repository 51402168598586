define("discourse/plugins/discourse-epic-games/discourse/components/ue-badges", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/d-icon", "discourse/plugins/discourse-epic-games/discourse/lib/constants", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _constants, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UeBadges extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get userBadgeIds() {
      if (!this.args.badges) {
        return [];
      }
      return this.args.badges.split(",").map(e => parseInt(e, 10));
    }
    get badgeOverflow() {
      if (this.args.limit && this.args.limit < this.earnedBadges.length) {
        return this.earnedBadges.length - this.args.limit;
      }
    }
    get earnedBadges() {
      let badgeIds = this.userBadgeIds;
      if (!badgeIds) {
        return;
      }
      if (this.args.limit) {
        badgeIds = this.userBadgeIds.slice(0, this.args.limit);
      }
      return _constants.EPIC_BADGES.filter(_ref => {
        let {
          id
        } = _ref;
        return badgeIds.includes(id);
      });
    }
    get parsedTeamMemberships() {
      if (this.args.teamMemberships) {
        return JSON.parse(this.args.teamMemberships).memberships;
      }
      return [];
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @displayName}}
          <a href={{@url}}>
            <div class="ue-badges-for-post__display-name">{{@displayName}}</div>
          </a>
        {{/if}}
        {{#if @username}}
          <a href={{@url}}>
            <div class="ue-badges-for-post__username">@{{@username}}</div>
          </a>
        {{/if}}
    
        {{#if this.parsedTeamMemberships}}
          <div class="ue-badges-for-post__team-memberships">
            {{#each this.parsedTeamMemberships as |team|}}
              <a
                href="{{this.siteSettings.epic_developer_url}}/community/profile/organization/{{team.hash_id}}/{{team.slug}}"
                class="ue-badges-for-post__membership"
              >
                {{team.name}}
              </a>
            {{/each}}
          </div>
        {{/if}}
    
        <div class="ue-badges-for-post__badges">
          {{#if this.earnedBadges}}
            {{#each this.earnedBadges as |badge|}}
              <span class="unreal-badge unreal-badge-{{badge.id}}">
                {{#if badge.icon}}
                  {{icon badge.icon}}
                {{/if}}
                {{badge.name}}
              </span>
            {{/each}}
    
            {{#if this.badgeOverflow}}
              <a href={{@url}}>
                <span class="unreal-badge unreal-badge-overflow">
                  +{{this.badgeOverflow}}
                </span>
              </a>
            {{/if}}
          {{/if}}
        </div>
      
    */
    {
      "id": "UYJ4WGsC",
      "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[10,3],[15,6,[30,2]],[12],[1,\"\\n        \"],[10,0],[14,0,\"ue-badges-for-post__display-name\"],[12],[1,[30,1]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,3],[[[1,\"      \"],[10,3],[15,6,[30,2]],[12],[1,\"\\n        \"],[10,0],[14,0,\"ue-badges-for-post__username\"],[12],[1,\"@\"],[1,[30,3]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"parsedTeamMemberships\"]],[[[1,\"      \"],[10,0],[14,0,\"ue-badges-for-post__team-memberships\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"parsedTeamMemberships\"]]],null]],null],null,[[[1,\"          \"],[10,3],[15,6,[29,[[30,0,[\"siteSettings\",\"epic_developer_url\"]],\"/community/profile/organization/\",[30,4,[\"hash_id\"]],\"/\",[30,4,[\"slug\"]]]]],[14,0,\"ue-badges-for-post__membership\"],[12],[1,\"\\n            \"],[1,[30,4,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"ue-badges-for-post__badges\"],[12],[1,\"\\n\"],[41,[30,0,[\"earnedBadges\"]],[[[42,[28,[31,2],[[28,[31,2],[[30,0,[\"earnedBadges\"]]],null]],null],null,[[[1,\"          \"],[10,1],[15,0,[29,[\"unreal-badge unreal-badge-\",[30,5,[\"id\"]]]]],[12],[1,\"\\n\"],[41,[30,5,[\"icon\"]],[[[1,\"              \"],[1,[28,[32,0],[[30,5,[\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"            \"],[1,[30,5,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[5]],null],[1,\"\\n\"],[41,[30,0,[\"badgeOverflow\"]],[[[1,\"          \"],[10,3],[15,6,[30,2]],[12],[1,\"\\n            \"],[10,1],[14,0,\"unreal-badge unreal-badge-overflow\"],[12],[1,\"\\n              +\"],[1,[30,0,[\"badgeOverflow\"]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@displayName\",\"@url\",\"@username\",\"team\",\"badge\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/ue-badges.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UeBadges;
});