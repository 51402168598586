define("discourse/plugins/discourse-epic-games/discourse/connectors/bread-crumbs-left/forum-link", ["exports", "@glimmer/component", "@ember/service", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ForumLink extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "topicTrackingState", [_service.service]))();
    #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
    get currentFilter() {
      const filter = this.site.global_filters?.find(f => f.name === this.site.get("globalFilter"));
      const filterName = filter ? filter.alternate_name || filter.name.replace(/-/g, " ") : "";
      const suffix = (0, _discourseI18n.i18n)("discourse_epic_games.breadcrumbs_header.title");
      return filter ? `${filterName} ${suffix}` : suffix;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.site.desktopView}}
          <a
            href="/categories?tag={{this.site.globalFilter}}"
            class="unreal-breadcrumb-item breadcrumb-home"
          >
            {{this.currentFilter}}
          </a>
    
          {{#if this.topicTrackingState.filterCategory}}
            <span class="unreal-breadcrumb-separator">/</span>
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "Rcpkvidi",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"      \"],[10,3],[15,6,[29,[\"/categories?tag=\",[30,0,[\"site\",\"globalFilter\"]]]]],[14,0,\"unreal-breadcrumb-item breadcrumb-home\"],[12],[1,\"\\n        \"],[1,[30,0,[\"currentFilter\"]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"topicTrackingState\",\"filterCategory\"]],[[[1,\"        \"],[10,1],[14,0,\"unreal-breadcrumb-separator\"],[12],[1,\"/\"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/bread-crumbs-left/forum-link.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ForumLink;
});