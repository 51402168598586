define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-participant/topic-participant", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "@ember/template", "truth-helpers", "discourse/components/user-avatar-flair", "discourse/lib/avatar-utils", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _template, _truthHelpers, _userAvatarFlair, _avatarUtils, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicParticipant extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get participant() {
      return this.args.outletArgs.participant;
    }
    get avatarImage() {
      return (0, _template.htmlSafe)((0, _avatarUtils.avatarImg)({
        avatarTemplate: this.participant.avatar_template,
        size: "medium",
        title: this.participant.name || this.participant.username
      }));
    }
    get participantClasses() {
      const {
        primary_group_name
      } = this.participant;
      return [primary_group_name ? `group-${primary_group_name}` : null].filter(Boolean).flat(3).join(" ");
    }
    get linkClasses() {
      return ["poster", this.args.outletArgs.toggledUsers?.has(this.participant.username) ? "toggled" : null].filter(Boolean).join(" ");
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class={{this.participantClasses}}>
          <a
            class={{this.linkClasses}}
            data-user-card={{this.participant.username}}
            title={{this.participant.username}}
            href={{concat
              this.siteSettings.epic_developer_url
              "/community/api/user_profiles/tab-redirect/about?discourse_username="
              this.participant.username
            }}
          >
            {{this.avatarImage}}
            {{#if (gt this.participant.post_count 1)}}
              <span class="post-count">{{this.participant.post_count}}</span>
            {{/if}}
            <UserAvatarFlair @user={{this.participant}} />
          </a>
        </div>
      
    */
    {
      "id": "ZDfyO5S+",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"participantClasses\"]]],[12],[1,\"\\n      \"],[10,3],[15,0,[30,0,[\"linkClasses\"]]],[15,\"data-user-card\",[30,0,[\"participant\",\"username\"]]],[15,\"title\",[30,0,[\"participant\",\"username\"]]],[15,6,[28,[32,0],[[30,0,[\"siteSettings\",\"epic_developer_url\"]],\"/community/api/user_profiles/tab-redirect/about?discourse_username=\",[30,0,[\"participant\",\"username\"]]],null]],[12],[1,\"\\n        \"],[1,[30,0,[\"avatarImage\"]]],[1,\"\\n\"],[41,[28,[32,1],[[30,0,[\"participant\",\"post_count\"]],1],null],[[[1,\"          \"],[10,1],[14,0,\"post-count\"],[12],[1,[30,0,[\"participant\",\"post_count\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[8,[32,2],null,[[\"@user\"],[[30,0,[\"participant\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-participant/topic-participant.js",
      "scope": () => [_helper.concat, _truthHelpers.gt, _userAvatarFlair.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicParticipant;
});