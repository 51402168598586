define("discourse/plugins/discourse-epic-games/discourse/connectors/mobile-subcategories-with-featured-topics-list/mobile-subcategories-with-featured-topics-list", ["exports", "discourse/components/category-title-link", "discourse/components/parent-category-row", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _categoryTitleLink, _parentCategoryRow, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MobileSubcategoriesWithFeaturedTopicsList = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="category-list subcategory-list with-topics">
      <div class="parent-category">
        <CategoryTitleLink @category={{@outletArgs.category}} />
        <span
          class="stat"
          title="{{@outletArgs.category.post_count}} {{i18n
            'discourse_epic_games.topic_list.posts_all_time'
          }}"
        >
          {{! Updated stat is total post count instead of topics per week }}
          {{@outletArgs.category.post_count}}
          <span class="stat-period">
            {{i18n "discourse_epic_games.topic_list.posts_all_time"}}
          </span>
        </span>
      </div>
      <div class="subcategories">
        {{#each @outletArgs.category.subcategories as |subCategory|}}
          <ParentCategoryRow @category={{subCategory}} @showTopics={{true}} />
        {{else}}
          {{! No subcategories... so just show the parent to avoid confusion }}
          <ParentCategoryRow
            @category={{@outletArgs.category}}
            @showTopics={{true}}
          />
        {{/each}}
      </div>
    </div>
  
  */
  {
    "id": "DEJvgmxN",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"category-list subcategory-list with-topics\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"parent-category\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@category\"],[[30,1,[\"category\"]]]],null],[1,\"\\n      \"],[10,1],[14,0,\"stat\"],[15,\"title\",[29,[[30,1,[\"category\",\"post_count\"]],\" \",[28,[32,1],[\"discourse_epic_games.topic_list.posts_all_time\"],null]]]],[12],[1,\"\\n\"],[1,\"        \"],[1,[30,1,[\"category\",\"post_count\"]]],[1,\"\\n        \"],[10,1],[14,0,\"stat-period\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"discourse_epic_games.topic_list.posts_all_time\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"subcategories\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"category\",\"subcategories\"]]],null]],null],null,[[[1,\"        \"],[8,[32,2],null,[[\"@category\",\"@showTopics\"],[[30,2],true]],null],[1,\"\\n\"]],[2]],[[[1,\"        \"],[8,[32,2],null,[[\"@category\",\"@showTopics\"],[[30,1,[\"category\"]],true]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\",\"subCategory\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/mobile-subcategories-with-featured-topics-list/mobile-subcategories-with-featured-topics-list.js",
    "scope": () => [_categoryTitleLink.default, _discourseI18n.i18n, _parentCategoryRow.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "mobile-subcategories-with-featured-topics-list:MobileSubcategoriesWithFeaturedTopicsList"));
  var _default = _exports.default = MobileSubcategoriesWithFeaturedTopicsList;
});