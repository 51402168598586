define("discourse/plugins/discourse-epic-games/discourse/connectors/category-list-before-topics-section/topics-section", ["exports", "discourse/helpers/d-icon", "discourse/helpers/number", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _dIcon, _number, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TopicsSection = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <td class="topics">
      <div title={{@outletArgs.category.topic_count}}>
        {{dIcon "ue-discussion"}}
        {{number @outletArgs.category.topic_count}}
      </div>
    </td>
  
  */
  {
    "id": "xTb5heED",
    "block": "[[[1,\"\\n  \"],[10,\"td\"],[14,0,\"topics\"],[12],[1,\"\\n    \"],[10,0],[15,\"title\",[30,1,[\"category\",\"topic_count\"]]],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"ue-discussion\"],null]],[1,\"\\n      \"],[1,[28,[32,1],[[30,1,[\"category\",\"topic_count\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/category-list-before-topics-section/topics-section.js",
    "scope": () => [_dIcon.default, _number.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "topics-section:TopicsSection"));
  var _default = _exports.default = TopicsSection;
});