define("discourse/plugins/discourse-epic-games/discourse/lib/dsa-report-url", ["exports", "discourse/lib/get-url"], function (_exports, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = generateUrl;
  function generateUrl(_ref) {
    let {
      contentUrl,
      contentType,
      dsaReportUrl
    } = _ref;
    const returnUrl = (0, _getUrl.getAbsoluteURL)(`${contentUrl}?content_review=true`);
    const encodedReturnUrl = encodeURIComponent(returnUrl);
    return `${dsaReportUrl}?product_id=edc_content_report&content_url=${encodedReturnUrl}&content_type=${contentType}`;
  }
});