define("discourse/plugins/discourse-epic-games/discourse/components/new-topic-dropdown", ["exports", "@glimmer/component", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse-i18n", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-epic-games/discourse/lib/digital-consent", "discourse/plugins/discourse-epic-games/discourse/lib/open-composer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _object, _service, _dButton, _discourseI18n, _dropdownSelectBox, _digitalConsent, _openComposer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class NewTopicDropdown extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "tooltip", [_service.service]))();
    #tooltip = (() => (dt7948.i(this, "tooltip"), void 0))();
    options = {
      icon: "plus",
      showFullTitle: true,
      autoFilterable: false,
      filterable: false,
      showCaret: true,
      none: "topic.create"
    };
    get hasFormTemplate() {
      return this.args.category?.form_template_ids?.length > 0;
    }
    get disableNewTopic() {
      return (0, _digitalConsent.hasDigitalConsentPendingGroup)(this.currentUser);
    }
    get content() {
      const items = [{
        id: "new_discussion",
        name: (0, _discourseI18n.i18n)("discourse_epic_games.new_topic_button"),
        description: (0, _discourseI18n.i18n)("discourse_epic_games.new_topic_description"),
        icon: "comment"
      }];
      items.push({
        id: "new_question",
        name: (0, _discourseI18n.i18n)("discourse_epic_games.question_button"),
        description: (0, _discourseI18n.i18n)("discourse_epic_games.question_description"),
        icon: "circle-question"
      });
      if (this.site.globalFilter === "epic-games-store") {
        items.push({
          id: "new_private_question",
          name: (0, _discourseI18n.i18n)("discourse_epic_games.private_question.label"),
          description: (0, _discourseI18n.i18n)("discourse_epic_games.private_question.description"),
          icon: "lock"
        });
      }
      return items;
    }
    onChange(selectedAction) {
      // If the user has pending consent, we should not allow them to create a new topic
      if ((0, _digitalConsent.default)(this.currentUser, this.tooltip, this.site, "new_topic_dropdown")) {
        return;
      }
      let refid = "";
      if (selectedAction === "new_issue_report") {
        const urlParams = new URLSearchParams(window.location.search);
        refid = urlParams.get("refid");
      }
      const opts = {
        selectedAction,
        categoryId: this.args.category?.id,
        hasFormTemplate: this.hasFormTemplate,
        refid
      };
      (0, _openComposer.openComposer)(opts);
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
    static #_6 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.hasFormTemplate}}
          <DButton
            @label="discourse_epic_games.new_issue_button"
            id="new_issue_report"
            @icon="circle-exclamation"
            @action={{fn this.onChange "new_issue_report"}}
            class="btn-primary epic-new-topic-button"
          />
        {{else}}
          {{#if this.disableNewTopic}}
            <DButton
              @label="topic.create"
              @id="disabled-new-topic"
              @icon="plus"
              @action={{this.onChange}}
              class="btn-primary epic-new-topic-button"
            />
          {{else}}
            <DropdownSelectBox
              @content={{this.content}}
              @onChange={{this.onChange}}
              @options={{this.options}}
              class="epic-new-topic-button"
              @id="epic-new-topic-dropdown"
            />
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "fU78y4vX",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"hasFormTemplate\"]],[[[1,\"      \"],[8,[32,0],[[24,1,\"new_issue_report\"],[24,0,\"btn-primary epic-new-topic-button\"]],[[\"@label\",\"@icon\",\"@action\"],[\"discourse_epic_games.new_issue_button\",\"circle-exclamation\",[28,[32,1],[[30,0,[\"onChange\"]],\"new_issue_report\"],null]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"disableNewTopic\"]],[[[1,\"        \"],[8,[32,0],[[24,0,\"btn-primary epic-new-topic-button\"]],[[\"@label\",\"@id\",\"@icon\",\"@action\"],[\"topic.create\",\"disabled-new-topic\",\"plus\",[30,0,[\"onChange\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[32,2],[[24,0,\"epic-new-topic-button\"]],[[\"@content\",\"@onChange\",\"@options\",\"@id\"],[[30,0,[\"content\"]],[30,0,[\"onChange\"]],[30,0,[\"options\"]],\"epic-new-topic-dropdown\"]],null],[1,\"\\n\"]],[]]]],[]]],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/new-topic-dropdown.js",
      "scope": () => [_dButton.default, _helper.fn, _dropdownSelectBox.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = NewTopicDropdown;
});