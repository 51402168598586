define("discourse/plugins/discourse-epic-games/discourse/components/topic-list-filter", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/controller", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "truth-helpers", "discourse/helpers/concat-class", "discourse/lib/decorators", "discourse/lib/topic-list-tracker", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _controller, _helper, _modifier, _object, _service, _truthHelpers, _concatClass, _decorators, _topicListTracker, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicListFilter extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "discoveryListController", [(0, _controller.inject)("discovery/list")]))();
    #discoveryListController = (() => (dt7948.i(this, "discoveryListController"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "keyword", [_tracking.tracked], function () {
      return "";
    }))();
    #keyword = (() => (dt7948.i(this, "keyword"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "tag", [_tracking.tracked], function () {
      return "";
    }))();
    #tag = (() => (dt7948.i(this, "tag"), void 0))();
    get topicListFilterTags() {
      return this.siteSettings.epic_topic_list_filter_tags.split("|");
    }
    get tagQuery() {
      // if the global filter tag matches the selected tag, we don't need to add it to the query
      // otherwise we need to add it to the query, but we prefix it with `+` to indicate that it's an AND query
      // as we want to filter by both the global filter tag and the tag that was clicked
      if (this.tag && this.tag !== this.args.filterTag) {
        return `tags:${this.args.filterTag}+${this.tag}`;
      } else {
        return `tag:${this.args.filterTag}`;
      }
    }
    filter(event) {
      // Since we can't have spaces in the url, we replace them with underscores
      // and then gsub them back to spaces in the query.
      this.keyword = event.target.value.replace(/\s/g, "_");
      this.updateTopics();
    }
    static #_6 = (() => dt7948.n(this.prototype, "filter", [(0, _decorators.debounce)(100)]))();
    async onTagClick(tag) {
      this.tag = this.tag === tag ? "" : tag;
      this.updateTopics();
    }
    static #_7 = (() => dt7948.n(this.prototype, "onTagClick", [_object.action]))();
    async updateTopics() {
      const list = await this.store.findFiltered("topicList", {
        filter: "filter",
        params: {
          q: `topic_contains:${this.keyword} ${this.tagQuery} category:${this.args.category}`
        }
      });
      (0, _topicListTracker.setTopicList)(list);
      this.discoveryListController.set("model.list", list);
    }
    static #_8 = (() => dt7948.n(this.prototype, "updateTopics", [_object.action]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.siteSettings.epic_topic_list_filter_tags}}
          <div class="topic-list-filter-tags">
            {{! template-lint-disable no-invalid-interactive }}
            {{#each this.topicListFilterTags as |tag|}}
              <div
                class={{concatClass
                  "topic-list-filter-tag"
                  (if (eq this.tag tag) "active")
                }}
                data-tag-name={{tag}}
                {{on "click" (fn this.onTagClick tag)}}
              >
                {{tag}}
              </div>
            {{/each}}
          </div>
        {{/if}}
    
        <input
          type="text"
          {{on "input" this.filter}}
          id="epic-topic-list-filter"
          placeholder={{i18n
            "discourse_epic_games.topic_list_filter_input_placeholder"
          }}
        />
      
    */
    {
      "id": "OqUFW4qM",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"epic_topic_list_filter_tags\"]],[[[1,\"      \"],[10,0],[14,0,\"topic-list-filter-tags\"],[12],[1,\"\\n\"],[42,[28,[31,2],[[28,[31,2],[[30,0,[\"topicListFilterTags\"]]],null]],null],null,[[[1,\"          \"],[11,0],[16,0,[28,[32,0],[\"topic-list-filter-tag\",[52,[28,[32,1],[[30,0,[\"tag\"]],[30,1]],null],\"active\"]],null]],[16,\"data-tag-name\",[30,1]],[4,[32,2],[\"click\",[28,[32,3],[[30,0,[\"onTagClick\"]],[30,1]],null]],null],[12],[1,\"\\n            \"],[1,[30,1]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[11,\"input\"],[24,1,\"epic-topic-list-filter\"],[16,\"placeholder\",[28,[32,4],[\"discourse_epic_games.topic_list_filter_input_placeholder\"],null]],[24,4,\"text\"],[4,[32,2],[\"input\",[30,0,[\"filter\"]]],null],[12],[13],[1,\"\\n  \"]],[\"tag\"],false,[\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/topic-list-filter.js",
      "scope": () => [_concatClass.default, _truthHelpers.eq, _modifier.on, _helper.fn, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicListFilter;
});