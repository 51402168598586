define("discourse/plugins/discourse-epic-games/discourse/initializers/epic-profile-expander", ["exports", "discourse/lib/api", "discourse/lib/decorators", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _api, _decorators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // always show expanded profiles
  var _default = _exports.default = (0, _api.apiInitializer)("1.6.1", api => {
    api.modifyClass("controller:user", dt7948.p({
      pluginId: _constants.PLUGIN_ID,
      forceExpand: true,
      canExpandProfile() {
        return true;
      },
      collapsedInfo(profileHidden, isSummaryRoute, viewingSelf, forceExpand) {
        if (profileHidden) {
          return true;
        }
        return !forceExpand;
      }
    }, [["method", "canExpandProfile", [(0, _decorators.default)("viewingSelf", "model.profile_hidden")]], ["method", "collapsedInfo", [(0, _decorators.default)("model.profile_hidden", "isSummaryRoute", "viewingSelf", "forceExpand")]]]));
  });
});