define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-list-after-title/status-badge", ["exports", "@glimmer/component", "discourse/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _dIcon, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class StatusBadge extends _component.default {
    get isQuestion() {
      const tags = this.args.outletArgs.topic?.tags;
      return tags?.includes("question");
    }
    get hasAnswer() {
      return this.args.outletArgs.topic.has_accepted_answer;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.isQuestion}}
          {{#if this.hasAnswer}}
            <span class="qa-badge-solved">
              {{dIcon "square-check"}}
              {{i18n "discourse_epic_games.topic_status.status_badge.solved"}}
            </span>
          {{else}}
            <span class="qa-badge-unsolved">
              {{i18n "discourse_epic_games.topic_status.status_badge.unsolved"}}
            </span>
          {{/if}}
        {{/if}}
      
    */
    {
      "id": "bcTu8mTo",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isQuestion\"]],[[[41,[30,0,[\"hasAnswer\"]],[[[1,\"        \"],[10,1],[14,0,\"qa-badge-solved\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"square-check\"],null]],[1,\"\\n          \"],[1,[28,[32,1],[\"discourse_epic_games.topic_status.status_badge.solved\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"qa-badge-unsolved\"],[12],[1,\"\\n          \"],[1,[28,[32,1],[\"discourse_epic_games.topic_status.status_badge.unsolved\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-list-after-title/status-badge.js",
      "scope": () => [_dIcon.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = StatusBadge;
});