define("discourse/plugins/discourse-epic-games/discourse/components/header-breadcrumbs", ["exports", "@glimmer/component", "@ember/controller", "@ember/helper", "@ember/modifier", "@ember/object", "@ember/service", "discourse/helpers/d-icon", "discourse-i18n", "select-kit/components/dropdown-select-box", "discourse/plugins/discourse-epic-games/discourse/lib/open-composer", "@ember/component", "@ember/template-factory"], function (_exports, _component, _controller, _helper, _modifier, _object, _service, _dIcon, _discourseI18n, _dropdownSelectBox, _openComposer, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HeaderBreadcrumbs extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "topicTrackingState", [_service.service]))();
    #topicTrackingState = (() => (dt7948.i(this, "topicTrackingState"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "topic", [_controller.inject]))();
    #topic = (() => (dt7948.i(this, "topic"), void 0))();
    get category() {
      return this.topic.model?.category;
    }
    get categories() {
      let filterCategory;
      if (this.router.currentRouteName.startsWith("topic.")) {
        filterCategory = this.category;
      } else {
        filterCategory = this.topicTrackingState.get("filterCategory");
      }
      if (filterCategory?.parentCategory) {
        return [filterCategory.parentCategory, filterCategory];
      } else if (filterCategory) {
        return [filterCategory];
      } else {
        return null;
      }
    }
    get homepageLink() {
      const filter = this.site.get("globalFilter");
      if (filter) {
        return `/?tag=${filter}`;
      }
      return "/";
    }
    get homepageTitle() {
      const filter = this.site.global_filters?.find(f => f.name === this.site.globalFilter);
      return filter ? filter.alternate_name || filter.name.replace(/-/g, " ") : "";
    }
    get applicationSelectors() {
      return (this.site.global_filters || []).map(filter => ({
        id: filter.id,
        name: (filter.alternate_name || filter.name).replace(/-/g, " "),
        description: filter.description,
        icon: `gf-${filter.name}`
      }));
    }
    onChange(selectedAction) {
      (0, _openComposer.openComposer)({
        selectedAction,
        categoryId: this.category?.id,
        hasFormTemplate: this.hasFormTemplate
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
    onFilterDropdownChange(filterId) {
      const filter = this.site.global_filters.find(f => f.id === filterId).name;
      this.routeToGlobalFilter(null, filter);
    }
    static #_7 = (() => dt7948.n(this.prototype, "onFilterDropdownChange", [_object.action]))();
    routeToGlobalFilter(e) {
      let filter = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.site.globalFilter;
      e?.preventDefault();
      const url = `/categories?tag=${filter}`;
      // this is a hack to ensure that the global filter is fully updated
      // we do the same in the global filter plugin when changing the filter
      window.location.href = url;
    }
    static #_8 = (() => dt7948.n(this.prototype, "routeToGlobalFilter", [_object.action]))();
    static #_9 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="wrap">
          <ul class="unreal-header">
            <li class="unreal-header__title">
              <a href={{this.siteSettings.epic_developer_url}}>
                {{icon "ue-home"}}
                {{i18n "discourse_epic_games.breadcrumbs_header.developer"}}
              </a>
            </li>
    
            {{#unless this.site.mobileView}}
              <a
                href
                {{on "click" this.routeToGlobalFilter}}
                class="unreal-header__category"
              >
                {{i18n "discourse_epic_games.breadcrumbs_header.title"}}
              </a>
            {{/unless}}
    
            <li class="unreal-header__category">
              <a href {{on "click" this.routeToGlobalFilter}}>
                {{this.homepageTitle}}
              </a>
              <DropdownSelectBox
                @content={{this.applicationSelectors}}
                @options={{hash
                  careDownIcon="ue-caret-down"
                  caretUpIcon="ue-caret-up"
                  showCaret=true
                }}
                @id="breadcrumb-global-filter-picker"
                @onChange={{this.onFilterDropdownChange}}
              />
            </li>
    
            {{#if this.site.desktopView}}
              {{#each this.categories as |category|}}
                <li class="unreal-header__category">
                  <a href={{category.url}}>{{category.name}}</a>
                </li>
              {{/each}}
            {{/if}}
          </ul>
        </div>
      
    */
    {
      "id": "+n5u8Z5/",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"wrap\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"unreal-header\"],[12],[1,\"\\n        \"],[10,\"li\"],[14,0,\"unreal-header__title\"],[12],[1,\"\\n          \"],[10,3],[15,6,[30,0,[\"siteSettings\",\"epic_developer_url\"]]],[12],[1,\"\\n            \"],[1,[28,[32,0],[\"ue-home\"],null]],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_epic_games.breadcrumbs_header.developer\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[51,[30,0,[\"site\",\"mobileView\"]]],[[[1,\"          \"],[11,3],[24,6,\"\"],[24,0,\"unreal-header__category\"],[4,[32,2],[\"click\",[30,0,[\"routeToGlobalFilter\"]]],null],[12],[1,\"\\n            \"],[1,[28,[32,1],[\"discourse_epic_games.breadcrumbs_header.title\"],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[10,\"li\"],[14,0,\"unreal-header__category\"],[12],[1,\"\\n          \"],[11,3],[24,6,\"\"],[4,[32,2],[\"click\",[30,0,[\"routeToGlobalFilter\"]]],null],[12],[1,\"\\n            \"],[1,[30,0,[\"homepageTitle\"]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[32,3],null,[[\"@content\",\"@options\",\"@id\",\"@onChange\"],[[30,0,[\"applicationSelectors\"]],[28,[32,4],null,[[\"careDownIcon\",\"caretUpIcon\",\"showCaret\"],[\"ue-caret-down\",\"ue-caret-up\",true]]],\"breadcrumb-global-filter-picker\",[30,0,[\"onFilterDropdownChange\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[42,[28,[31,3],[[28,[31,3],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"unreal-header__category\"],[12],[1,\"\\n              \"],[10,3],[15,6,[30,1,[\"url\"]]],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"category\"],false,[\"unless\",\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/header-breadcrumbs.js",
      "scope": () => [_dIcon.default, _discourseI18n.i18n, _modifier.on, _dropdownSelectBox.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = HeaderBreadcrumbs;
});