define("discourse/plugins/discourse-epic-games/discourse/initializers/dock-mobile-title", ["exports", "discourse/lib/api", "discourse/lib/decorators", "discourse/plugins/discourse-epic-games/discourse/lib/constants"], function (_exports, _api, _decorators, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // always dock the title on scroll
  var _default = _exports.default = (0, _api.apiInitializer)("1.6.1", api => {
    const site = api.container.lookup("service:site");
    if (site.desktopView) {
      return;
    }
    api.modifyClass("component:discourse-topic", dt7948.p({
      pluginId: _constants.PLUGIN_ID,
      shouldShowTopicInHeader(topic, offset) {
        return offset > this.dockAt;
      },
      toggleMobileHeaderTopic() {
        return this.appEvents.trigger("header:update-topic", this.topic);
      }
    }, [["method", "toggleMobileHeaderTopic", [(0, _decorators.observes)("mobileScrollDirection")]]]));
  });
});