define("discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-results-type-top/type-header", ["exports", "@glimmer/component", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TypeHeader extends _component.default {
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @outletArgs.resultType.results.length}}
          <h5 class="result-type-header">{{this.text}}</h5>
        {{/if}}
      
    */
    {
      "id": "x+Fe/rAV",
      "block": "[[[1,\"\\n\"],[41,[30,1,[\"resultType\",\"results\",\"length\"]],[[[1,\"      \"],[10,\"h5\"],[14,0,\"result-type-header\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/search-menu-results-type-top/type-header.js",
      "isStrictMode": true
    }), this))();
    get text() {
      return (0, _discourseI18n.i18n)(`discourse_epic_games.search_menu.types.${this.args.outletArgs.resultType.type}`);
    }
  }
  _exports.default = TypeHeader;
});