define("discourse/plugins/discourse-epic-games/discourse/connectors/topic-above-post-stream/topic-status-bar", ["exports", "@glimmer/component", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TopicStatusBar extends _component.default {
    get isQuestion() {
      return this.args?.outletArgs?.model?.tags?.includes("question");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.isQuestion}}
          <div
            class="custom-topic-status-bar
              {{if @outletArgs.model.accepted_answer 'resolved'}}"
          >
            <span>
              {{i18n "discourse_epic_games.topic_status.qa_topic"}}
              <span>|</span>
              {{#if @outletArgs.model.accepted_answer}}
                {{i18n "discourse_epic_games.topic_status.status_badge.solved"}}
              {{else}}
                {{i18n "discourse_epic_games.topic_status.status_badge.unsolved"}}
              {{/if}}
            </span>
          </div>
        {{/if}}
      
    */
    {
      "id": "MmTkiaMz",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"isQuestion\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"custom-topic-status-bar\\n          \",[52,[30,1,[\"model\",\"accepted_answer\"]],\"resolved\"]]]],[12],[1,\"\\n        \"],[10,1],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.qa_topic\"],null]],[1,\"\\n          \"],[10,1],[12],[1,\"|\"],[13],[1,\"\\n\"],[41,[30,1,[\"model\",\"accepted_answer\"]],[[[1,\"            \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.status_badge.solved\"],null]],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[28,[32,0],[\"discourse_epic_games.topic_status.status_badge.unsolved\"],null]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@outletArgs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/topic-above-post-stream/topic-status-bar.js",
      "scope": () => [_discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = TopicStatusBar;
});