define("discourse/plugins/discourse-epic-games/discourse/initializers/set-category-drop-options", ["exports", "discourse/lib/get-owner", "discourse/lib/plugin-api", "select-kit/components/category-drop"], function (_exports, _getOwner, _pluginApi, _categoryDrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-set-category-drop-options",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        replaceCategoryDropdownContent(api);
      });
    }
  };
  function replaceCategoryDropdownContent(api) {
    // this will replace the content of the category drop with the filtered categories and subcategories
    api.modifySelectKit("category-drop").replaceContent(categoryDrop => {
      const epicFilteredCategories = (0, _getOwner.getOwnerWithFallback)(this).lookup("service:epic-filtered-categories");
      const globalFilterCategories = epicFilteredCategories.categories?.content;
      // if no category, return the default content
      if (!globalFilterCategories) {
        return categoryDrop.content;
      }
      let categoryDropContent = categoryDrop.content || [];
      const categoryDropParentClasslist = document.getElementById(categoryDrop.elementId).parentElement.classList;
      const isSubcategoriesDrop = categoryDropParentClasslist.contains("gft-subcategories-drop");
      if (isSubcategoriesDrop) {
        categoryDropContent = categoryDrop.content.filter(category => {
          return epicFilteredCategories.subcategories.some(filteredCategory => filteredCategory.id === category.id);
        });

        // For the furthest nested subcategory we provide an option to remove the current filter
        // which is this case is the subcategory itself
        if (categoryDrop.value && !categoryDrop.editingCategory || categoryDrop.selectKit.options.noSubcategories && categoryDrop.selectKit.options.subCategory) {
          categoryDropContent.unshift({
            id: _categoryDrop.ALL_CATEGORIES_ID,
            name: categoryDrop.allCategoriesLabel
          });
        }
      } else {
        categoryDropContent = categoryDrop.content.filter(category => {
          return globalFilterCategories.some(filteredCategory => filteredCategory.id === category.id);
        });
      }
      if (categoryDrop.selectKit.filter) {
        const filter = categoryDrop.selectKit.filter.toLowerCase();
        categoryDropContent = categoryDropContent.filter(c => {
          const name = categoryDrop.getName(c)?.toLowerCase();
          return name?.includes(filter);
        });
      }
      return categoryDropContent;
    });
  }
});