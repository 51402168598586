define("discourse/plugins/discourse-epic-games/discourse/connectors/category-list-before-category-mobile/parent-category-row-mobile", ["exports", "@glimmer/component", "discourse/components/category-title-link", "discourse/helpers/border-color", "discourse/helpers/concat-class", "discourse/helpers/d-icon", "discourse/helpers/dir-span", "@ember/component", "@ember/template-factory"], function (_exports, _component, _categoryTitleLink, _borderColor, _concatClass, _dIcon, _dirSpan, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ParentCategoryRowMobile extends _component.default {
    get category() {
      return this.args.outletArgs.category;
    }
    get isMuted() {
      return this.args.outletArgs.isMuted;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <tr
          data-category-id={{this.category.id}}
          data-notification-level={{this.category.notificationLevelString}}
          class={{concatClass
            (if
              this.category.description_excerpt "has-description" "no-description"
            )
            (if this.category.uploaded_logo.url "has-logo" "no-logo")
            (if this.category.subcategories "has-subcategories")
          }}
        >
          <td
            class={{concatClass
              "category"
              (if this.category.subcategories "parent-category")
              (if this.isMuted "muted")
              (if this.category.noCategoryStyle "no-category-style")
            }}
            style={{unless
              this.category.noCategoryStyle
              (borderColor this.category.color)
            }}
          >
            <CategoryTitleLink @category={{this.category}} />
            {{#if this.category.description_excerpt}}
              <div class="category-description">
                {{dirSpan this.category.description_excerpt}}
              </div>
            {{/if}}
            {{! We have removed sub-subcategories so they are not being displayed on the category list, on mobile  }}
          </td>
    
          <td class="topics">
            <div title={{this.category.topics_all_time}}>
              {{dIcon "ue-discussion"}}
              {{! Updated stat is total post count instead of topics per week }}
              {{this.category.post_count}}
            </div>
          </td>
        </tr>
      
    */
    {
      "id": "db8NmFVE",
      "block": "[[[1,\"\\n    \"],[10,\"tr\"],[15,\"data-category-id\",[30,0,[\"category\",\"id\"]]],[15,\"data-notification-level\",[30,0,[\"category\",\"notificationLevelString\"]]],[15,0,[28,[32,0],[[52,[30,0,[\"category\",\"description_excerpt\"]],\"has-description\",\"no-description\"],[52,[30,0,[\"category\",\"uploaded_logo\",\"url\"]],\"has-logo\",\"no-logo\"],[52,[30,0,[\"category\",\"subcategories\"]],\"has-subcategories\"]],null]],[12],[1,\"\\n      \"],[10,\"td\"],[15,0,[28,[32,0],[\"category\",[52,[30,0,[\"category\",\"subcategories\"]],\"parent-category\"],[52,[30,0,[\"isMuted\"]],\"muted\"],[52,[30,0,[\"category\",\"noCategoryStyle\"]],\"no-category-style\"]],null]],[15,5,[52,[51,[30,0,[\"category\",\"noCategoryStyle\"]]],[28,[32,1],[[30,0,[\"category\",\"color\"]]],null]]],[12],[1,\"\\n        \"],[8,[32,2],null,[[\"@category\"],[[30,0,[\"category\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"category\",\"description_excerpt\"]],[[[1,\"          \"],[10,0],[14,0,\"category-description\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[[30,0,[\"category\",\"description_excerpt\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\\n      \"],[10,\"td\"],[14,0,\"topics\"],[12],[1,\"\\n        \"],[10,0],[15,\"title\",[30,0,[\"category\",\"topics_all_time\"]]],[12],[1,\"\\n          \"],[1,[28,[32,4],[\"ue-discussion\"],null]],[1,\"\\n\"],[1,\"          \"],[1,[30,0,[\"category\",\"post_count\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\",\"unless\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/category-list-before-category-mobile/parent-category-row-mobile.js",
      "scope": () => [_concatClass.default, _borderColor.default, _categoryTitleLink.default, _dirSpan.default, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ParentCategoryRowMobile;
});