define("discourse/plugins/discourse-epic-games/discourse/components/category-matrix", ["exports", "@glimmer/component", "@ember/helper", "@ember/service", "discourse/helpers/category-badge", "discourse-i18n", "discourse/plugins/discourse-epic-games/discourse/components/category-matrix-checkbox", "@ember/component", "@ember/template-factory"], function (_exports, _component, _helper, _service, _categoryBadge, _discourseI18n, _categoryMatrixCheckbox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryMatrix extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    categoriesSorting = "position";
    filteredCategories = (() => this.site.categories.filter(c => c.id !== this.site.uncategorized_category_id && c.parent_category_id !== this.site.uncategorized_category_id) || [])();
    constructor() {
      super(...arguments);
      this.#reorderChildren(null, 0, 0);
    }
    #reorderChildren(categoryId, depth, index) {
      this.filteredCategories.forEach(category => {
        if (categoryId === null && !category.get("parent_category_id") || category.get("parent_category_id") === categoryId) {
          category.position = index++;
          category.depth = depth;
          index = this.#reorderChildren(category.get("id"), depth + 1, index);
        }
      });
      return index;
    }
    get categoriesOrdered() {
      return this.filteredCategories.sortBy(this.categoriesSorting);
    }
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <table class="sticky-header">
          <thead>
            <tr>
              <th></th>
              {{#each @filterTags as |filterTag|}}
                <th>
                  {{filterTag.name}}
                </th>
              {{/each}}
            </tr>
          </thead>
          <tbody>
            {{#each this.categoriesOrdered as |category|}}
              <tr class={{concat "global-filter--c-depth-" category.depth}}>
                <td>
                  {{categoryBadge category}}
                </td>
    
                {{#each @filterTags as |filterTag|}}
                  <td>
                    <CategoryMatrixCheckbox
                      @filterTag={{filterTag}}
                      @category={{category}}
                      @setCategoryIdsForTag={{@setCategoryIdsForTag}}
                    />
                  </td>
                {{/each}}
              </tr>
            {{/each}}
          </tbody>
        </table>
    
        <div class="global-filter--empty-column-note">
          <p>{{i18n "discourse_epic_games.admin.empty_note"}}</p>
        </div>
      
    */
    {
      "id": "MF9qSAWS",
      "block": "[[[1,\"\\n    \"],[10,\"table\"],[14,0,\"sticky-header\"],[12],[1,\"\\n      \"],[10,\"thead\"],[12],[1,\"\\n        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"th\"],[12],[13],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"            \"],[10,\"th\"],[12],[1,\"\\n              \"],[1,[30,2,[\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"categoriesOrdered\"]]],null]],null],null,[[[1,\"          \"],[10,\"tr\"],[15,0,[28,[32,0],[\"global-filter--c-depth-\",[30,3,[\"depth\"]]],null]],[12],[1,\"\\n            \"],[10,\"td\"],[12],[1,\"\\n              \"],[1,[28,[32,1],[[30,3]],null]],[1,\"\\n            \"],[13],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1]],null]],null],null,[[[1,\"              \"],[10,\"td\"],[12],[1,\"\\n                \"],[8,[32,2],null,[[\"@filterTag\",\"@category\",\"@setCategoryIdsForTag\"],[[30,4],[30,3],[30,5]]],null],[1,\"\\n              \"],[13],[1,\"\\n\"]],[4]],null],[1,\"          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"global-filter--empty-column-note\"],[12],[1,\"\\n      \"],[10,2],[12],[1,[28,[32,3],[\"discourse_epic_games.admin.empty_note\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@filterTags\",\"filterTag\",\"category\",\"filterTag\",\"@setCategoryIdsForTag\"],false,[\"each\",\"-track-array\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/components/category-matrix.js",
      "scope": () => [_helper.concat, _categoryBadge.default, _categoryMatrixCheckbox.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryMatrix;
});