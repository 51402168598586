define("discourse/plugins/discourse-epic-games/discourse/connectors/subcategories-with-featured-topics-list/subcategories-with-featured-topics-list", ["exports", "discourse/components/category-title-link", "discourse/components/parent-category-row", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _categoryTitleLink, _parentCategoryRow, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const SubcategoriesWithFeaturedTopicsList = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <table class="category-list subcategory-list with-topics">
      <thead>
        <tr>
          <th class="parent-category">
            <CategoryTitleLink @category={{@outletArgs.category}} />
            <span class="stat">
              {{! Updated stat is posts per week instead of topics per week }}
              {{@outletArgs.category.posts_week}}
              <span class="stat-period">
                {{i18n "discourse_epic_games.topic_list.per_week"}}
              </span>
            </span>
          </th>
          <th class="topics">{{i18n "categories.topics"}}</th>
          <th class="replies">{{i18n "replies"}}</th>
          <th class="latest">{{i18n "categories.latest"}}</th>
        </tr>
      </thead>
      <tbody aria-labelledby="categories-only-category">
        {{! subcategory_list from global filters does not include children (unless specifically configured) }}
        {{#each @outletArgs.category.subcategories as |subCategory|}}
          <ParentCategoryRow @category={{subCategory}} @showTopics={{true}} />
        {{else}}
          {{! No subcategories... show nothing (core shows parent) }}
        {{/each}}
      </tbody>
    </table>
  
  */
  {
    "id": "AhsjHA7J",
    "block": "[[[1,\"\\n  \"],[10,\"table\"],[14,0,\"category-list subcategory-list with-topics\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,0,\"parent-category\"],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@category\"],[[30,1,[\"category\"]]]],null],[1,\"\\n          \"],[10,1],[14,0,\"stat\"],[12],[1,\"\\n\"],[1,\"            \"],[1,[30,1,[\"category\",\"posts_week\"]]],[1,\"\\n            \"],[10,1],[14,0,\"stat-period\"],[12],[1,\"\\n              \"],[1,[28,[32,1],[\"discourse_epic_games.topic_list.per_week\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"topics\"],[12],[1,[28,[32,1],[\"categories.topics\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"replies\"],[12],[1,[28,[32,1],[\"replies\"],null]],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"latest\"],[12],[1,[28,[32,1],[\"categories.latest\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[14,\"aria-labelledby\",\"categories-only-category\"],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,1,[\"category\",\"subcategories\"]]],null]],null],null,[[[1,\"        \"],[8,[32,2],null,[[\"@category\",\"@showTopics\"],[[30,2],true]],null],[1,\"\\n\"]],[2]],[[],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@outletArgs\",\"subCategory\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-epic-games/discourse/connectors/subcategories-with-featured-topics-list/subcategories-with-featured-topics-list.js",
    "scope": () => [_categoryTitleLink.default, _discourseI18n.i18n, _parentCategoryRow.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "subcategories-with-featured-topics-list:SubcategoriesWithFeaturedTopicsList"));
  var _default = _exports.default = SubcategoriesWithFeaturedTopicsList;
});