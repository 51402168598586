define("discourse/plugins/discourse-epic-games/discourse/initializers/require-tag-on-topic-creation", ["exports", "rsvp", "discourse/lib/plugin-api", "discourse-i18n"], function (_exports, _rsvp, _pluginApi, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "epic-require-tag-on-topic-creation",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        api.composerBeforeSave(() => {
          return new _rsvp.Promise((resolve, reject) => {
            const composerModel = api.container.lookup("service:composer").model;

            // only require tags when creating a regular topic
            // i.e. skip validation for PMs, replies, edits, etc.
            if (composerModel.action !== "createTopic" || composerModel.archetypeId !== "regular") {
              return resolve();
            }
            const globalFilters = api.container.lookup("service:site-settings").global_filters.split("|");
            if (composerModel.tags.filter(tag => globalFilters.includes(tag)).length > 0) {
              return resolve();
            } else {
              const dialog = api.container.lookup("service:dialog");
              dialog.alert((0, _discourseI18n.i18n)("discourse_epic_games.require_tag_on_topic_creation.error"));
              return reject();
            }
          });
        });
      });
    }
  };
});